import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
	{
	  path: '/login',
	  name: 'login',
	  component: () => import(/* webpackChunkName: "about" */ '../views/Login/index.vue')
	},
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Payment.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Payment.vue')
  },
  {
    path: '/reView',
    name: 'reView',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReView.vue')
  },
  {
    path: '/posPayment',
    name: 'posPayment',
    component: () => import(/* webpackChunkName: "about" */ '../views/PosPayment.vue')
  },
  {
    path: '/mchPayment',
    name: 'mchPayment',
    component: () => import(/* webpackChunkName: "about" */ '../views/MchPayment.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import(/* webpackChunkName: "about" */ '../views/History.vue')
  },
  {
    path: '/yuHistory',
    name: 'yuHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/YuHistory.vue')
  },
  {
    path: '/mchHistory',
    name: 'mchHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/MchHistory.vue')
  },
  {
    path: '/payHistory',
    name: 'payHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/PayHistory.vue')
  },
  {
    path: '/scanTest',
    name: 'scanTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/ScanTest.vue')
  },
  {
    path: '/payResult',
    name: 'payResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/PayResult.vue')
  },
  {
    path: '/refundResult',
    name: 'refundResult',
    component: () => import(/* webpackChunkName: "about" */ '../views/RefundResult.vue')
  },
  {
    path: '/fwxy',
    name: 'fwxy',
    component: () => import(/* webpackChunkName: "about" */ '../views/fwxy.vue')
  },
  {
    path: '/dianjin',
    name: 'dianjin',
    component: () => import(/* webpackChunkName: "about" */ '../views/dianjin.vue')
  },
  {
    path: '/yszc',
    name: 'yszc',
    component: () => import(/* webpackChunkName: "about" */ '../views/yszc.vue')
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import(/* webpackChunkName: "about" */ '../views/invoice.vue')
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: () => import(/* webpackChunkName: "about" */ '../views/insurance.vue')
  },
  {
    path: '/insurance3',
    name: 'insurance3',
    component: () => import(/* webpackChunkName: "about" */ '../views/insurance3.vue')
  },
  {
    path: '/insurance2',
    name: 'insurance2',
    component: () => import(/* webpackChunkName: "about" */ '../views/insurance2.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
